import { IStudentCourseCompetencyForThinkificModel } from '../domain/store/StudentStore';

export const useAvailableWeeks = (
  studentCompetencies: IStudentCourseCompetencyForThinkificModel[]
) => {
  const unitName = 'Unit';
  const numberOfUnitsThatCanBeBookedAhead = 2;

  if (!studentCompetencies || studentCompetencies.length === 0)
    return { unlocked: [0, 1, 2], competenciesBlocking: [] };

  let studentLastCompletedWeek: number | null = null;

  let competenciesNeededToUnlockNextUnit: string[] = [];

  for (let i = 0; i < studentCompetencies.length; i++) {
    const competency = studentCompetencies[i];

    const isCompleted = competency?.status === 'C';

    var weekZero =
      competency.competencyTitle.toLowerCase().includes('warm up') ||
      competency.competencyTitle.toLowerCase().includes('warmup');

    if (isCompleted) {
      if (weekZero) {
        studentLastCompletedWeek = 0;
      } else if (
        competency.competencyTitle.includes(unitName) &&
        !competency.group1?.toLowerCase().includes('review')
      ) {
        const match = competency.competencyTitle.match(new RegExp(`${unitName} (\\d{1,2})`));
        if (match) {
          const week = parseInt(match[1]);
          studentLastCompletedWeek = week;
        }
      }
    } else {
      //first check if there are any units left to be unlocked
      let countUnitCompetencies = 0;
      for (let h = 0; h < studentCompetencies.length; h++) {
        if (studentCompetencies[h].competencyTitle.includes(unitName)) {
          countUnitCompetencies++;
        }
      }

      if (
        (studentLastCompletedWeek ?? -1) + numberOfUnitsThatCanBeBookedAhead >=
        countUnitCompetencies
      ) {
        competenciesNeededToUnlockNextUnit = [];
        break;
      }

      // identify competencies that unlock the next unit
      const firstIncompleteCompetency = studentCompetencies[i];
      competenciesNeededToUnlockNextUnit.push(firstIncompleteCompetency.competencyTitle);

      for (let j = i + 1; j < studentCompetencies.length; j++) {
        // check if the next competencies are also required to unlock the next unit
        const isUnitCompetency = studentCompetencies[j].competencyTitle.includes(unitName);

        if (!isUnitCompetency && studentCompetencies[j].status !== 'C') {
          competenciesNeededToUnlockNextUnit.push(studentCompetencies[j].competencyTitle);
        } else if (!isUnitCompetency && studentCompetencies[j].status === 'C') {
          // do nothing, keep looking for missing competencies
        } else {
          if (
            !firstIncompleteCompetency.competencyTitle.includes(unitName) &&
            studentCompetencies[j].status !== 'C'
          ) {
            competenciesNeededToUnlockNextUnit.push(studentCompetencies[j].competencyTitle);
          }
          break;
        }
      }
      break;
    }
  }

  let weeks: number[] = [];

  if (studentLastCompletedWeek === null || studentLastCompletedWeek === 0) {
    weeks = [0, 1, 2];
  } else {
    const weekCanBookUpTo = Math.min(
      20,
      studentLastCompletedWeek + numberOfUnitsThatCanBeBookedAhead
    );
    weeks = Array.from({ length: weekCanBookUpTo + 1 }, (_, i) => i);
  }

  return { unlocked: weeks, competenciesBlocking: competenciesNeededToUnlockNextUnit };
};
