import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import styles from './Settings.module.scss';
import { CustomFields } from './CustomFields';
import { Tags } from './Tags';
import { CourseCompetencies } from './CourseCompetencies';
import { TeachingSkillsSettings } from './TeachingSkillsSettings';

export const Settings = () => {
  return (
    <div className={styles.container}>
      <h1>Settings</h1>
      <br />
      <Accordion className={styles.accordion}>
        <AccordionSummary className={styles.accordionSummary}>Custom Fields</AccordionSummary>
        <AccordionDetails>
          <CustomFields />
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion className={styles.accordion}>
        <AccordionSummary className={styles.accordionSummary}>Tags</AccordionSummary>
        <AccordionDetails>
          <Tags />
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion className={styles.accordion}>
        <AccordionSummary className={styles.accordionSummary}>Course Competencies</AccordionSummary>
        <AccordionDetails>
          <CourseCompetencies />
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion className={styles.accordion}>
        <AccordionSummary className={styles.accordionSummary}>
          Teaching Skills Bookings
        </AccordionSummary>
        <AccordionDetails>
          <TeachingSkillsSettings />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
