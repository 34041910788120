import { useEffect, useState } from 'react';
import { ITeachingSkillsConfiguration } from '../domain/store/customTypes/ITeachingSkillsConfiguration';
import { IKeyValueModel } from '../domain/store/SettingsStore';
import { useStore } from '../views/hooks';

export const useTeachingSkillsSetting = () => {
  const { settingsStore } = useStore();
  const [settings, setSettings] = useState<ITeachingSkillsConfiguration | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const value: IKeyValueModel = await settingsStore.getKeyValuePublic(
          'TeachingSkillsSettings'
        );
        if (value) {
          const data = JSON.parse(value.value) as ITeachingSkillsConfiguration;
          setSettings(data);
        } else {
          setError('Settings not found');
        }
      } catch (err) {
        setError(`Error fetching settings: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [settingsStore]);

  return { settings, loading, error };
};
