import React, { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import { useStore } from '../../hooks';
import { ITeachingSkillsConfiguration } from '../../../domain/store/customTypes/ITeachingSkillsConfiguration';
import { IKeyValueModel } from '../../../domain/store/SettingsStore';

export const TeachingSkillsSettings = () => {
  const { settingsStore } = useStore();

  const [unit, setUnit] = useState('');
  const [titles, setTitles] = useState<string[]>(Array(21).fill(''));

  useEffect(() => {
    settingsStore.getKeyValue('TeachingSkillsSettings').then((value: IKeyValueModel) => {
      if (value) {
        const data = JSON.parse(value.value) as ITeachingSkillsConfiguration;
        setUnit(data.unit);
        setTitles(data.titles);
      } else {
        alert('Not found');
      }
    });
  }, [settingsStore]);

  const handleTitleChange = (index: number, value: string) => {
    const newTitles = [...titles];
    newTitles[index] = value;
    setTitles(newTitles);
  };

  const handleSave = () => {
    const data: ITeachingSkillsConfiguration = { unit, titles };
    settingsStore
      .upsertKeyValue('TeachingSkillsSettings', JSON.stringify(data))
      .then(() => alert('Settings saved successfully'))
      .catch((error) => alert(`Error saving settings: ${error}`));
  };

  return (
    <div className={styles.teachingskillssettings}>
      <div className={styles.inputgroup}>
        <label>
          Unit Name:
          <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} />
        </label>
      </div>
      {titles.map((title, index) => (
        <div key={index} className={styles.inputgroup}>
          <label>
            {unit} {index} Title:
            <input
              type="text"
              value={title}
              onChange={(e) => handleTitleChange(index, e.target.value)}
            />
          </label>
        </div>
      ))}
      <button onClick={handleSave}>Save</button>
    </div>
  );
};
