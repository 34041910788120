import { useEffect } from 'react';
import { AuthenticationProvider } from '../auth/AuthenticationProvider';

function useTokenRefresh(authProvider: AuthenticationProvider) {
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        console.log('Refreshing token');
        await authProvider.refreshToken();
        console.log('Token refreshed');
      } catch (error) {
        console.error('Failed to refresh token', error);
      }
    }, 60 * 1000); // Refresh token every minute

    return () => clearInterval(interval);
  }, [authProvider]);
}

export default useTokenRefresh;
