import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';

export const CustomFieldModel = types.model('CustomFieldsModel', {
  id: types.identifierNumber,
  fieldName: types.string,
  fieldDescription: types.string,
  fieldType: types.string,
  fieldScope: types.string,
  createdTimestamp: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  deletedTimestamp: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
});

export const TagModel = types.model('TagModel', {
  id: types.identifierNumber,
  tag: types.string,
  tagDescription: types.string,
  level: types.string,
  fieldScope: types.string,
  createdTimestamp: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  deletedTimestamp: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
});

export const KeyValueModel = types.model('KeyValueModel', {
  id: types.identifierNumber,
  key: types.string,
  value: types.string,
  createdTimestamp: types.maybeNull(types.string),
});

export interface ICustomFieldModel extends Instance<typeof CustomFieldModel> {}

export interface IKeyValueModel extends Instance<typeof KeyValueModel> {}

export interface ITagModel extends Instance<typeof TagModel> {}

export const SettingsStore = types
  .model('SettingsStore', {
    customFields: types.array(CustomFieldModel),
    tags: types.array(TagModel),
  })
  .actions((self) => ({
    getCustomFields: flow(function* () {
      if (self.customFields.length > 0) {
        return self.customFields;
      }
      const { ajax } = getEnv(self);
      try {
        self.customFields = yield ajax
          .get(`ProtectedSettings/CustomFields`)
          .json<ICustomFieldModel[]>()
          .then((value) => value.map((i: ICustomFieldModel) => CustomFieldModel.create(i)));
        return self.customFields;
      } catch (error) {
        console.log(`error ProtectedSettings/CustomFields error: ${error}`);
      }
    }),
    getTags: flow(function* () {
      if (self.tags.length > 0) {
        return self.tags;
      }
      const { ajax } = getEnv(self);
      try {
        self.tags = yield ajax
          .get(`ProtectedSettings/Tags`)
          .json<ITagModel[]>()
          .then((value) => value.map((i: ITagModel) => TagModel.create(i)));

        return self.tags;
      } catch (error) {
        console.log(`error ProtectedSettings/Tags error: ${error}`);
      }
    }),
    upsertTag: flow(function* (tagModel: ITagModel) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`ProtectedSettings/UpsertTag`, { json: tagModel })
          .json<number>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST ProtectedSettings/UpsertTag error: ${error}`);
      }
    }),
    deleteTag: flow(function* (tagId: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`ProtectedSettings/DeleteTag?tagId=` + tagId)
          .json<number>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST ProtectedSettings/DeleteTag error: ${error}`);
      }
    }),
    upsertCustomField: flow(function* (field: ICustomFieldModel) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`ProtectedSettings/UpsertCustomField`, { json: field })
          .json<number>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST ProtectedSettings/UpsertCustomField error: ${error}`);
      }
    }),
    deleteCustomField: flow(function* (fieldId: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`ProtectedSettings/DeleteCustomField?customFieldId=` + fieldId)
          .json<number>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST ProtectedSettings/DeleteCustomField error: ${error}`);
      }
    }),
    getKeyValue: flow(function* (key: string) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(`ProtectedSettings/KeyValue?key=${key}`)
          .json<IKeyValueModel>()
          .then((value) => value);
      } catch (error) {
        console.log(`error ProtectedSettings/KeyValue error: ${error}`);
      }
    }),
    getKeyValuePublic: flow(function* (key: string) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(`PublicSettings/KeyValue?key=${key}`)
          .json<IKeyValueModel>()
          .then((value) => value);
      } catch (error) {
        console.log(`error PublicSettings/KeyValue error: ${error}`);
      }
    }),
    upsertKeyValue: flow(function* (key: string, value: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`ProtectedSettings/KeyValue`, { json: { key, value } })
          .json<number>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST ProtectedSettings/KeyValue error: ${error}`);
      }
    }),
  }))
  .views((self) => ({}));
