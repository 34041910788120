import React, { FC, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
} from '@material-ui/core';
import styles from './BookingSystem.module.scss';
import { ArrowBack, Clear } from '@material-ui/icons';
import { ISession } from './BookingSystem';
import { IStudentCourseCompetencyForThinkificModel } from '../../../domain/store/StudentStore';
import { useAvailableWeeks } from '../../../utils/bookingSystemHelpers';
import LockIcon from '@material-ui/icons/Lock';
import { useTeachingSkillsSetting } from '../../../hooks/useTeachingSkillsSetting';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';

interface BookingSystemConfirmationProps {
  selectedSession: ISession;
  onModalClose: () => void;
  onMakeBooking: (week: string, equipment: string) => void;
  isStartHereMode: boolean;
  studentCompetencies: IStudentCourseCompetencyForThinkificModel[];
  isTrialUser: boolean;
}

export const BookingSystemConfirmation: FC<BookingSystemConfirmationProps> = function ({
  selectedSession,
  onMakeBooking,
  onModalClose,
  isStartHereMode,
  studentCompetencies,
  isTrialUser,
}) {
  const [registrationQuestionWeek, setRegistrationQuestionWeek] = useState<string>('');
  const [registrationQuestionEquipment, setRegistrationQuestionEquipment] = useState<string>('');
  const [termsCheckbox, setTermsCheckbox] = React.useState(false);

  const availableWeeks = useAvailableWeeks(studentCompetencies);

  const teachingSkillsSettings = useTeachingSkillsSetting();

  if (isTrialUser) {
    return (
      <>
        <Modal
          className={styles.modal}
          open={!!selectedSession}
          onClose={onModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={!!selectedSession}>
            <div className={styles.paper}>
              <IconButton aria-label="close" onClick={onModalClose} className={styles.closeButton}>
                <Clear />
              </IconButton>

              <h1 id="transition-modal-title" className={styles.paragraphHeading}>
                Ooops!
              </h1>

              <p>Looks like you’re on a free trial!</p>
              <p>Enroll now to book live workshops and take your learning to the next level.</p>

              <FormControl component="fieldset" fullWidth={true}>
                <Box mt={4}>
                  <Box component="span" mr={2}>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={onModalClose}
                      startIcon={<ArrowBack />}>
                      Back
                    </Button>
                  </Box>
                </Box>
              </FormControl>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  if (selectedSession.blockedDueTo2TsIn24Hours) {
    return (
      <>
        <Modal
          className={styles.modal}
          open={!!selectedSession}
          onClose={onModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={!!selectedSession}>
            <div className={styles.paper}>
              <IconButton aria-label="close" onClick={onModalClose} className={styles.closeButton}>
                <Clear />
              </IconButton>

              <h1 id="transition-modal-title" className={styles.paragraphHeading}>
                Limit Reached
              </h1>

              <p>You cannot have more than 2 Teaching Skills Workshops in a 24-hour window.</p>

              <p>
                This limit is to help you:
                <ul>
                  <li>
                    <b>Focus and Fully Participate</b>
                  </li>
                  <li>
                    <b>Distribute Your Practice</b>
                  </li>
                  <li>
                    <b>Practice Safely</b>
                  </li>
                </ul>
              </p>

              <FormControl component="fieldset" fullWidth={true}>
                <Box mt={4}>
                  <Box component="span" mr={2}>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={onModalClose}
                      startIcon={<ArrowBack />}>
                      Back
                    </Button>
                  </Box>
                </Box>
              </FormControl>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  if (selectedSession.blockedDueTo6TsIn7Days) {
    return (
      <>
        <Modal
          className={styles.modal}
          open={!!selectedSession}
          onClose={onModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={!!selectedSession}>
            <div className={styles.paper}>
              <IconButton aria-label="close" onClick={onModalClose} className={styles.closeButton}>
                <Clear />
              </IconButton>

              <h1 id="transition-modal-title" className={styles.paragraphHeading}>
                Limit Reached
              </h1>

              <p>You cannot have more than 6 Teaching Skills Workshops in a 7-day window.</p>

              <FormControl component="fieldset" fullWidth={true}>
                <Box mt={4}>
                  <Box component="span" mr={2}>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={onModalClose}
                      startIcon={<ArrowBack />}>
                      Back
                    </Button>
                  </Box>
                </Box>
              </FormControl>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal
        className={styles.modal}
        open={!!selectedSession}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={!!selectedSession}>
          <div className={styles.paper}>
            <IconButton aria-label="close" onClick={onModalClose} className={styles.closeButton}>
              <Clear />
            </IconButton>

            <h1 id="transition-modal-title" className={styles.paragraphHeading}>
              Book this session
            </h1>
            <FormControl component="fieldset" fullWidth={true}>
              {selectedSession.weekAndEquipmentRegistrationRequired && (
                <>
                  {availableWeeks.competenciesBlocking.length > 0 && (
                    <Alert severity="info" icon={<LockIcon />}>
                      <div className={styles.blockedunit}>
                        Unlock{' '}
                        <strong>
                          {
                            teachingSkillsSettings.settings?.titles[
                              _.max(availableWeeks.unlocked)! + 1
                            ]
                          }
                        </strong>{' '}
                        by completing these competencies:
                        <ul>
                          {availableWeeks.competenciesBlocking.map((competencyId) => (
                            <li key={competencyId}>
                              <Checkbox className={styles.disabledcheckbox} disabled={true} />
                              {competencyId}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Alert>
                  )}

                  <Select
                    className={styles.select}
                    variant="outlined"
                    value={registrationQuestionWeek}
                    displayEmpty
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setRegistrationQuestionWeek(event.target.value as string);
                    }}>
                    <MenuItem value="" disabled>
                      <span className={styles.inputLabel}>
                        {' '}
                        Select {teachingSkillsSettings.settings?.unit.toLowerCase() ?? 'unit'}{' '}
                        you'll be teaching this session
                      </span>
                    </MenuItem>

                    <MenuItem value="0">
                      {teachingSkillsSettings.settings?.titles[0] ??
                        'First Workshop - Warmup Cluster'}
                    </MenuItem>

                    {!isStartHereMode &&
                      Array.from({ length: 20 }, (_, i) => i + 1).map((i) => (
                        <MenuItem key={i} value={i} disabled={!availableWeeks.unlocked.includes(i)}>
                          {teachingSkillsSettings.settings?.titles[i] ?? 'Unit: ' + i}{' '}
                          {!availableWeeks.unlocked.includes(i) && <LockIcon fontSize={'small'} />}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              )}
            </FormControl>

            <FormControl component="fieldset" fullWidth={true}>
              {selectedSession.weekAndEquipmentRegistrationRequired && (
                <>
                  <Select
                    className={styles.select}
                    variant="outlined"
                    value={registrationQuestionEquipment}
                    displayEmpty
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setRegistrationQuestionEquipment(event.target.value as string);
                    }}>
                    <MenuItem value="" disabled>
                      <span className={styles.inputLabel}>
                        {' '}
                        Select equipment you will have available this session
                      </span>
                    </MenuItem>
                    <MenuItem value={'Mat'}>Mat only</MenuItem>
                    <MenuItem value={'Reformer'}>Reformer only</MenuItem>
                    <MenuItem value={'Mat/Reformer'}>Mat & Reformer</MenuItem>
                  </Select>
                </>
              )}
            </FormControl>
            <FormControl component="fieldset" fullWidth={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsCheckbox}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setTermsCheckbox(event.target.checked)
                    }
                    color={'primary'}
                    name="termsCheckbox"
                  />
                }
                label={
                  selectedSession.waitListAvailable
                    ? 'I understand that it is my responsibility to cancel workshops, or remove myself from the waitlist, at least 2 hours before the start time to avoid penalties.'
                    : 'I understand that it is my responsibility to cancel workshops at least 2 hours before the start time to avoid penalties.'
                }
              />

              <Box mt={4}>
                <Box component="span" mr={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={onModalClose}
                    startIcon={<ArrowBack />}>
                    Back
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={
                    (selectedSession.weekAndEquipmentRegistrationRequired
                      ? !registrationQuestionWeek || !registrationQuestionEquipment
                      : false) || !termsCheckbox
                  }
                  onClick={() => {
                    onMakeBooking(registrationQuestionWeek, registrationQuestionEquipment);
                  }}>
                  {selectedSession.slotsAvailable > 0 ? 'Complete booking' : 'Join Waitlist'}
                </Button>
              </Box>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
